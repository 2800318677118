@tailwind base;
@tailwind components;
@tailwind utilities;


/* 
    CSS for Login page
 */
.desktop-container {
    display: flex;
    /* Hide desktop-specific content on mobile */
}

.mobile-container {
    display: block;
    /* Display mobile-specific content on mobile */
}

.backgroundLogin {
    background: url("./assets/images/Signup-4.svg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroundLoginDark {
    background: url("./assets/images/Signup-3.svg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginFormContainer {
    max-width: 400px;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    box-sizing: border-box;
}

.scrollable-container {
    max-height: 100vh;
    /* Adjust the max height as needed */
    overflow-y: auto;
    overflow-x: auto;
}


.logoNuage {

    background: url("./assets/images/CloverMEV-Web-logo.png");
    background-size: contain;
    /* Adjust this property */
    background-position: center center;
    /* Adjust this property */
    background-repeat: no-repeat;
}

.logoAddUser {
    background: url("./assets/images/users.svg");

}


.logoReport {
    background: url("./assets/images/material-symbols_download.svg");

}

.logoReset {
    background: url("./assets/images/resetIcon.svg");
    background-size: contain;

    padding: 12px;
    box-sizing: border-box;
}

.logoLogout {
    background: url("./assets/images/material-symbols_logout.svg");
}

.logoCalendar {
    background: url("./assets/images/calendar.svg");
}

.logoChevrouUp {
    background: url("./assets/images/octicon_chevron-up-16.svg");
}

.logoArrowRight {
    background: url("./assets/images/arrow-right.svg");
}

.logoUserThumb {
    background: url("./assets/images/User-Thumb.png");

}

.logoBell {
    /* Set the background image */
    background: url("./assets/images/bell.png") no-repeat center center;
    /* Adjust the background size to fit the container */
    background-size: contain;

    padding: 12px;
    box-sizing: border-box;
}

.logoClose {
    background: url("./assets/images/close.svg");

}

.searchIcon {
    background: url("./assets/images/search.png");

}


.restaurant-table {
    display: flex;
    flex-direction: column;
}

.table-header {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    /* Adjust as per the number of columns */
    gap: 0;
    background-color: #F2F4F8;
    /* Set background color for header */
}

.table-row {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    /* Adjust as per the number of columns */
    gap: 0;
}

.table-cell {
    border-bottom: 1px solid #ddd;
    /* Add border */
    padding: 10px;
    background-color: #ffffff;
    /* Set background color for cells */
}

.table-header-cell {
    border-bottom: 1px solid #ddd;
    /* Add border */
    padding: 10px;
    background-color: #F2F4F8;
    /* Set background color for cells */
}

.cell-content {
    text-align: left;
}




@media (max-width: 768px) {
    .desktop-container {
        display: block;
    }

    .mobile-container {
        display: flex;
        width: 393px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #FFF;
        display: none;
    }

    .scroll-container {
        display: flex;
        overflow-x: auto;
        max-width: 100%;
    }

    .backgroundLogin {
        width: 100vw;
        height: 100vh;
        background: url("./assets/images/Mobile-Login-4.svg") no-repeat center center;
        background-size: cover;
    }

    .loginWelcome {
        display: flex;
        width: 393px;
        padding: 32px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        border: 1px solid #000;
    }

    .text_welcome_back {
        align-self: stretch;
        color: var(--dark, #004E6A);
        text-align: center;

        font-family: Roboto, Arial, sans-serif;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
    }

    .formLogin {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;

    }

    .emailLogin,
    .passwordLogin {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
    }

    .emailTextLogin,
    .passwordTextLogin {
        align-self: stretch;
        color: var(--dark, #004E6A);
        font-family: Roboto, Arial, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
    }

    .inputEmail,
    .inputPassword {
        display: flex;
        height: 48px;
        padding: 12px 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        border-bottom: 1px solid var(--CoolGray-30, #C1C7CD);
        background: var(--CoolGray-10, #F2F4F8);
    }


    .signInButton {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .buttonDesignSignIn,
    .googleButton {
        display: flex;
        height: 48px;
        padding: 16px 12px;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        border-radius: 8px;
    }

    .buttonDesignSignIn {
        border: 2px solid var(--dark, #004E6A);
        background: var(--dark, #004E6A);
    }

    .googleButton {
        border: 2px solid var(--dark, #004E6A);
    }

    .signInText {
        color: var(--Default-White, #FFF);

        font-family: Roboto, Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.5px;
    }

    .layoutGoogle {
        display: flex;
        padding-top: 24px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-top: 1px solid var(--CoolGray-20, #DDE1E6);
    }

    .googleSection {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

    }

    .textSignUpWith {
        font-family: Roboto, Arial, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        text-align: center;

    }

    hr.sep {
        width: 520px;
        height: 1px;
        background: #DDE1E6;
    }

    .textOr {
        align-self: stretch;
        color: var(--dark, #004E6A);
        text-align: center;

        font-family: Roboto, Arial, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    p.alreadyAccount {
        align-self: stretch;
        color: var(--dark, #004E6A);
        text-align: center;

        font-family: Roboto, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
    .searchIcon{
        background: url("./assets/images/search.png");
    }
}
